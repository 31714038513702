import React, { useState } from 'react';
import { Link } from 'gatsby';
import { WidgetIcon, Loader } from 'src/components/shared';
import PhasePageHeader from '../components/PhasePageHeader';
import { useEffect } from 'react';
import { getDynamicContent } from 'src/utils/sdk-utils';
import Tabs from 'src/components/ui/tabs/Tabs';
import TabsContent from 'src/components/ui/tabs/TabsContent';

import * as Styles from 'src/styles/phase-meals-layout.module.scss';
import classNames from 'classnames';
interface PhaseMealsLayoutProps {
    phaseName: string;
}

interface MealsTabs {
    label: string;
    content: Array<{ id: string; label: string }>;
}
interface Meals {
    intro: string;
    nextPhase: string;
    prevPhase: string;
    title: string;
    subtitle: string;
    recipesTab: Array<MealsTabs>;
}

const initialState = {
    intro: '',
    nextPhase: '',
    prevPhase: '',
    title: '',
    subtitle: '',
    recipesTab: [],
};

const PhaseMealsLayout = ({ phaseName }: PhaseMealsLayoutProps) => {
    const [pageContent, setPageContent] = useState<Meals>(initialState);
    const [loading, setLoading] = useState(true);

    const links = [
        {
            heading: 'Want to learn more?',
            icon: 'pv-mind',
            linkText: `${phaseName} DETAILS`,
            text: 'Dive into the details of the',
            url: `/more-about/${phaseName}`,
        },
        {
            heading: 'Ready to work out?',
            icon: 'pv-movement',
            linkText: `${phaseName} LIBRARY`,
            text: 'Choose a video from the',
            url: `/series/chapters/${phaseName}-phase`,
        },
        {
            heading: 'About to go grocery shopping?',
            icon: 'pv-foods',
            linkText: `${phaseName} FOODS`,
            text: 'Be sure to pick up some',
            url: `/foods/${phaseName}`,
        },
    ];

    useEffect(() => {
        let isMounted = true;
        const getContent = async (phaseName: string) => {
            try {
                setLoading(true);
                const phaseCapitalize = phaseName.charAt(0).toUpperCase() + phaseName.slice(1);
                const content = await getDynamicContent(
                    `PhaseAndFunction.MealDetails.Meals.${phaseCapitalize}`
                );
                if (content && content.length > 0) {
                    if (isMounted) {
                        const pageContentResponse = content[0].value;
                        setPageContent(pageContentResponse);
                        setLoading(false);
                    }
                }
            } catch (err) {
                console.log({ err });
                return {};
            }
        };

        getContent(phaseName);

        return () => {
            isMounted = false;
        };
    }, [phaseName]);

    const getIntro = (intro: String) => {
        const textLink = `${phaseName} foods list`;
        const textLinkIndex = intro?.indexOf(textLink);
        const textLeft = intro.slice(0, textLinkIndex);
        const textRight = intro.slice(textLinkIndex + textLink.length, intro.length);

        return (
            <p className={classNames('p1', Styles.phaseMealsIntro)}>
                {textLeft}
                <Link to={`/foods/${phaseName}`} className="bold">
                    {textLink}
                </Link>
                {textRight}
            </p>
        );
    };

    return loading ? (
        <div className="margin-top--large margin-bottom--large">
            <Loader />
        </div>
    ) : (
        <div className={classNames('display-column alignItems--center', Styles.phaseMeals)}>
            <div className={Styles.phaseMealsHeaderWrapper}>
                <PhasePageHeader
                    title={pageContent?.title}
                    subtitle={pageContent?.subtitle}
                    page={'meals'}
                    nextPhase={pageContent?.nextPhase}
                    prevPhase={pageContent?.prevPhase}
                >
                    {getIntro(pageContent?.intro)}
                </PhasePageHeader>
            </div>
            <div className={Styles.phaseMealsTabs}>
                <Tabs>
                    {!!pageContent?.recipesTab &&
                        pageContent?.recipesTab.map((recipe: MealsTabs, index: number) => (
                            <TabsContent label={recipe.label} key={index}>
                                {recipe.content.map((link, index) => (
                                    <h4
                                        className={classNames(
                                            'bold upper margin-top--xlarge',
                                            Styles.phaseMealsTabLink
                                        )}
                                    >
                                        <Link to={`/recipe/${phaseName}/${link.id}`} key={index}>
                                            {link.label}
                                        </Link>
                                    </h4>
                                ))}
                            </TabsContent>
                        ))}
                </Tabs>
            </div>
            <div className={Styles.phaseMealsLinks}>
                {links.map((link, index) => (
                    <WidgetIcon info={link} key={index} className={Styles.phaseMealsLink} />
                ))}
            </div>
        </div>
    );
};

export default PhaseMealsLayout;
